























import { defineComponent } from '@nuxtjs/composition-api';
import { useUiHelpers } from '~/composables';

export default defineComponent({
  name: 'FinancingSection',
  setup() {
    const { getFullUrl } = useUiHelpers();
    return {
      getFullUrl
    }
  },
  head() {
    let canonicalUrl:string = this.getFullUrl(this.$route.fullPath)
    return {
      link: [{
        rel: 'canonical',
        href: canonicalUrl
      }]
    }
  }
})
